



















import { Component, Vue, } from "vue-property-decorator";

@Component
export default class ModalComponent extends Vue {
    private onCloseClick() {
        this.toggleVisibility();
    }

    public toggleVisibility() {
        const element: HTMLDivElement = this.$refs.modalElement as HTMLDivElement;

        if (element.classList.contains("is-active")) {
            element.classList.remove("is-active");
        } else {
            element.classList.add("is-active");
        }
    }

    public isVisible() {
        const element: HTMLDivElement = this.$refs.modalElement as HTMLDivElement;

        return element.classList.contains("is-active");
    }
}
