


















import { Component, Vue } from "vue-property-decorator";
import GlobalEventBus from "@/common/GlobalEventBus";
import ModalComponent from "@/components/global/ModalComponent.vue";
import GlobalEvents from "@/constants/GlobalEvents";

@Component({
    components: {
        ModalComponent
    }
})
export default class ErrorModalComponent extends Vue {
    created() {
        GlobalEventBus.$on(GlobalEvents.ServerError, this.showModal);
    }

    private showModal() {
        const element = this.$refs.modal as ModalComponent;

        if (!element.isVisible()) {
            element.toggleVisibility();
        }
    }
}
