


















import { Component, Vue } from "vue-property-decorator";
import HeaderComponent from "@/components/HeaderComponent.vue";
import ConfigurationContext from "@/dataContexts/ConfigurationDataContext";
import FooterComponent from "@/components/FooterComponent.vue";
import OfflineView from "@/views/OfflineView.vue";
import ErrorModalComponent from "@/components/ErrorModalComponent.vue";
import Settings from "@/Settings";
import LocalStorageKeys from "@/constants/LocalStorageKeys";

@Component({
    components: {
        HeaderComponent,
        FooterComponent,
        OfflineView,
        ErrorModalComponent
    }
})
export default class App extends Vue {
    private dataContext: ConfigurationContext = new ConfigurationContext();
    private apiOffline = false;

    async created() {
        console.log(`[CLIENT BUILD INFO] Version Hash: ${Settings.Commit}`);

        const result = await this.dataContext.getVersion();

        if (result.successfully) {
            console.log(`[API BUILD INFO] Build Date: ${result.data?.buildDate}, Version Hash: ${result.data?.commit}`);

            if (result.data?.commit) {
                localStorage.setItem(LocalStorageKeys.LastVersion, result.data.commit);
            }
        } else {
            this.apiOffline = true;
        }
    }
}
