
























import { Vue } from "vue-property-decorator";
import { SearchResult } from "@sokkuri/common";
import _ from "lodash";

export default abstract class BaseSearchComponent extends Vue {
    private searchResults: SearchResult[] = [];
    private initState = true;

    private getUrl(result: SearchResult): string {
        return `/${result.dataType}/${result.dataId}`;
    }

    private resultVisibility(visibility: boolean) {
        const element = this.$refs.searchResults as HTMLDivElement;

        if (element) {
            if (visibility) {
                element.style.display = "";
            } else {
                setTimeout(() => element.style.display="none", 250);
            }
        }
    }

    private onInput = _.debounce(this.search, 700);

    private search() {
        const inputContainer = this.$refs.inputContainer as HTMLDivElement;
        const inputElement = this.$refs.inputElement as HTMLInputElement;
        const searchTerm = inputElement.value;

        if (searchTerm && /\S/.test(searchTerm) && searchTerm.length >= 3) {
            inputContainer.classList.add("is-loading");

            this.executeSearch(searchTerm).then(x => {
                if (x) {
                    this.searchResults = x;
                }
            }).finally(() => {
                inputContainer.classList.remove("is-loading");
                this.initState = false;
            });
        }
    }

    protected abstract executeSearch(searchTerm: string): Promise<SearchResult[] | undefined>;
}
