































































import { Component, Vue } from "vue-property-decorator";
import { UserSessionManager } from "kogitte";
import GlobalSearchComponent from "@/components/global/search/GlobalSearchComponent.vue";
import GlobalEventBus from "@/common/GlobalEventBus";
import CurrentUser from "@/common/CurrentUser";
import StringUtils from "@/common/utilities/StringUtils";
import { UserInfo } from "@sokkuri/common";
import GlobalEvents from "@/constants/GlobalEvents";

@Component({
    components: {
        GlobalSearchComponent
    }
})
export default class HeaderComponent extends Vue {
    private sessionExists = false;
    private userInfo: UserInfo = new UserInfo();

    private userSessionManager = new UserSessionManager();

    created() {
        GlobalEventBus.$on(GlobalEvents.UpdateLoginState, this.updateLoginState);
        GlobalEventBus.$on(GlobalEvents.LoadUserInfo, this.loadUserInfo);

        this.sessionExists = new UserSessionManager().sessionExists();
        this.loadUserInfo();
    }

    beforeDestroy() {
        GlobalEventBus.$off(GlobalEvents.UpdateLoginState, this.updateLoginState);
        GlobalEventBus.$off(GlobalEvents.LoadUserInfo, this.loadUserInfo);
    }

    private updateLoginState(state: string) {
        if (StringUtils.equalsIgnoreCase(state, "login")) {
            this.sessionExists = true;
            this.loadUserInfo();
        }

        if (StringUtils.equalsIgnoreCase(state, "logout")) {
            this.sessionExists = false;
        }
    }

    private async logout() {
        this.userSessionManager.logout().then(x => {
            if (x.successfully) {
                this.sessionExists = false;
            }
        });
    }

    private loadUserInfo() {
        const userInfo = CurrentUser.getUserInfo();

        if (userInfo) {
            this.userInfo = userInfo;
        }
    }
}
